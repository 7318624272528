import React, { Component } from 'react';
import { RiTeamFill } from 'react-icons/ri';

import Heading     from '@interness/web-core/src/components/text/Heading/Heading';
import Spacer      from '@interness/web-core/src/components/structure/Spacer/Spacer';
import Wrapper     from '@interness/web-core/src/components/structure/Wrapper/Wrapper';
import TeamMembers from '@interness/web-core/src/components/modules/TeamMembers';
import SEO         from '@interness/web-core/src/components/modules/SEO/SEO';

class TeamPage extends Component {
  render() {
    return (
      <Wrapper>
        <SEO title='Team' />
        <Spacer />
        <Heading subtitle={'Juwelier Hohage'} icon={<RiTeamFill />}>Team</Heading>
        <Spacer/>
        <TeamMembers layout={'grid'}/>
        <Spacer/>
      </Wrapper>
    )
  }
}

export default TeamPage;
